import { Component, HostListener, OnInit, ViewChild, Renderer2, ElementRef } from '@angular/core';
import { animate, AUTO_STYLE, state, style, transition, trigger } from '@angular/animations';
import { Router, NavigationEnd } from "@angular/router";

@Component({
  selector: 'app-main-navbar',
  templateUrl: './main-navbar.component.html',
  styleUrls: ['./main-navbar.component.scss'],
  animations: [
    trigger('dropdownAnimate', [
      state('an-off, void',
        style({
          overflow: 'hidden',
          height: '0px',
        })
      ),
      state('an-animate',
        style({
          overflow: 'hidden',
          height: AUTO_STYLE,
        })
      ),
      transition('an-off <=> an-animate', [
        animate('200ms ease-in-out')
      ])
    ]),
    trigger('fa-animate', [
      state('an-off, void',
        style({
          transform: 'rotate(0)'
        })
      ),
      state('an-animate',
        style({
          transform: 'rotate(180deg)'
        })
      ),
      transition('an-off <=> an-animate', [
        animate('200ms ease-in-out')
      ])
    ]),
  ]
})
export class MainNavbarComponent implements OnInit {
  isVisible: boolean;
  isFixed: boolean;
  drop_nav: string;
  isProductPage: boolean = false;
  loginUrl: string = '';

  @ViewChild('dropToggle') dropToggle: ElementRef;
  @ViewChild('dropToggleIcon') dropToggleIcon: ElementRef;

  constructor(private renderer: Renderer2,private router: Router) {
    this.drop_nav = 'an-off';
    this.renderer.listen('window', 'click',(e:Event)=>{
      if(e.target !== this.dropToggle.nativeElement &&
          e.target !== this.dropToggleIcon.nativeElement)
      this.drop_nav = 'an-off';
    })

    if(this.router.url === '/'){
    }else if(this.router.url === '/offering/babybeat' || 
              this.router.url === '/offering/fetosense' || 
              this.router.url === '/offering/anandimaa'){
      this.isProductPage = true;
      if(this.router.url === '/offering/babybeat'){
        this.loginUrl = 'https://babybeat.caremother.in';
      }else if(this.router.url === '/offering/fetosense'){
        this.loginUrl = 'https://fetosense.caremother.in';
      }else if(this.router.url === '/offering/anandimaa'){
        this.loginUrl = 'https://anandimaa.caremother.in';
      }
    }

    
  }

  ngOnInit() {
    if(typeof document !== "undefined"){
        
      const body = document.getElementById("body");
      body.classList.remove("fixed_body");
      var item = document.getElementById('dropNavItem');
      if(this.router.url === '/company/about-us' || 
        this.router.url === '/company/life-at-caremother' || 
        this.router.url === '/company/careers'){
          item.style.color = '#00A69A'
      }
    }
  }

  @HostListener("window:scroll", [])
  onWindowScroll() {
    if(typeof window !== undefined && document !== undefined ){
          
      const offset =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop ||
        0;
      if(offset > 100 && offset < 500){
        this.drop_nav = 'an-off'
      }
      if(this.router.url === '/' ||
        this.router.url === '/contact' ||
        this.router.url.includes('/offering')){
        if (offset > 400) {
          this.isFixed = true;
          if(offset> 500){
            this.isVisible = true;
          }else{
            this.isVisible = false;
          }
        } else if(offset <= 400){
          this.isFixed = false
          this.isVisible = false;
        }
        else {
          this.isFixed = false;
          this.isVisible = false;
        }
      }
    }
    
  }

  check:boolean = false;
  toggle:boolean = false
  onClick(){
    if(typeof document !== undefined){
        
      this.check = !this.check;
      this.toggle = !this.toggle;

      const body = document.getElementById("body");
      body.classList.toggle("fixed_body");
      if(typeof window !== undefined ){
            
        window.addEventListener("scroll", (e) => {
          e.preventDefault();
        });
      }
    }
  }
  hidemenu() {
    if(typeof document !== undefined){
        
      this.check = !this.check;
      this.toggle = !this.toggle;
      const body = document.getElementById("body");
      body.classList.remove("fixed_body");
    }
  }
  closeAll() {
    if(typeof document !== undefined){
        
      this.check = false;
      this.toggle = false;
      const body = document.getElementById("body");
      body.classList.remove("fixed_body");
    }
  }

  toggleDropdown() {
    this.drop_nav = this.drop_nav === 'an-off' ? 'an-animate' : 'an-off';
  }

  scrollTo(sectionName){
    if(typeof document !== undefined){
        
      let element = document.getElementById(sectionName);
      element.scrollIntoView({behavior:'smooth'});
    }
  }
}